






























import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'

Vue.component('VJsf', VJsf)

@Component
export default class Home extends Vue {
  private drawer: boolean = false

  private adminMenus: object[] = [
    {
      route: 'admin-tasks',
      name: 'Tasks',
    },
    {
      route: 'admin-activities',
      name: 'Activities',
    },
    {
      route: 'admin-locations',
      name: 'Locations',
    },
    {
      route: 'admin-task-types',
      name: 'Task Types',
    },
    {
      route: 'admin-language',
      name: 'Language',
    },
    {
      route: 'admin-theme',
      name: 'Theme',
    },
    {
      route: 'admin-access',
      name: 'Access',
    },
    {
      route: 'admin-settings',
      name: 'General',
    },
    {
      route: 'admin-reporting',
      name: 'Reporting',
    },
    {
      route: 'admin-api',
      name: 'API',
    },
    {
      route: 'admin-collections',
      name: 'Collections',
    },
  ]

  public created() {
    const root = document.getElementsByTagName('body')[0]
    root.classList.add('admin')
  }

  get currentPageTitle() {
    return this.$route?.meta?.title
  }
}
